<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div
          class="d-flex justify-content-between align-items-center pt-50 mb-2"
        >
          <div>
            <h2 class="font-weight-bolder m-0">Usuarios</h2>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <b-link :to="{ name: 'createUser' }">
                <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                  {{ "Añadir nuevo usuario" }}
                </b-button>
              </b-link>
            </div>
            <b-input-group class="input-group">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
                :placeholder="'Búsqueda'"
                @keydown.enter.native="handleSearch"
              />
              <b-button
                @click="handleSearch"
                variant="primary"
                size="sm"
                class="mr-1 ml-1"
              >
                {{ "Buscar" }}
              </b-button>
            </b-input-group>
          </div>
        </div>
        <b-card no-body>
          <b-table hover responsive :items="items" :fields="columns">
            <template #cell(fullName)="data">
              <div class="d-flex align-items-center">
                <b-avatar size="md" :src="data.item.avatar" />
                <span class="ml-1">
                  <b-link
                    :to="{ name: 'viewUser', params: { id: data.item.id } }"
                    class="link"
                  >
                    {{ data.item.name }}
                  </b-link>
                </span>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template>
            <template #cell(departments)="data">
              <div v-if="data.item.departments">
                <span v-for="(item, index) in data.item.departments" :key="item.id"
                  ><span v-if="index !== 0">, </span>{{ item.name }}</span
                >
              </div>
            </template>
            <template #cell(roles)="data">
              <div v-if="data.item.roles">
                <span v-for="item in data.item.roles" :key="item.id" class="mr-50">
                  {{ item.display_name }}
                </span>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewUser', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'editUser', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteUser(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BAvatar,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";

export default {
  components: {
    BAvatar,
    BBadge,
    BCard,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      department: "",
      pages: ["10", "25", "50"],
      columns: [
        {
          label: "Nombre",
          key: "fullName",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Departamentos",
          key: "departments",
        },
        {
          label: "Rol",
          key: "roles",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: "users/getItems",
      totalItems: "users/getTotalItems",
    }),
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
      };

      return (status) => statusColor[status];
    },
    statusVariantText() {
      const statusText = {
        1: "Activo",
        0: "Desactivado",
      };

      return (status) => statusText[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "users/getListUsers",
      delete: "users/delete",
    }),
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        roles: ["super_admin", "operario"],
        active: null,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteUser(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
